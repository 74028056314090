import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Anchor, Box, Grommet, Heading, Main } from 'grommet';
import Demo from './Demo';
import { MapList, UploadIndex }  from './Indexes';
import Upload from './Upload';
import Players from './Players';
import Aggregate from './Aggregate';
import { tadaTheme } from './Theme';
import './App.css';

export const tadaApi = {
	games: "https://z151e60yl7.execute-api.us-east-2.amazonaws.com/staging/games",
	players: "https://z151e60yl7.execute-api.us-east-2.amazonaws.com/staging/players",
	maps: "https://z151e60yl7.execute-api.us-east-2.amazonaws.com/staging/maps",
	upload: "https://z151e60yl7.execute-api.us-east-2.amazonaws.com/staging/upload",
	download: "https://z151e60yl7.execute-api.us-east-2.amazonaws.com/staging/download",
}

function App() {
	return (
		<Router>
		<Grommet theme={tadaTheme}>
		<Header />
		<Main pad="small">
		<Route path="/games/:gameId">
		<Demo />
		</Route>
		<Route path="/maps/:mapName">
		<MapList />
		</Route>
		<Route exact path="/maps">
		<MapList />
		</Route>
		<Route path="/upload">
		<Upload />
		</Route>
		<Route path="/players">
		<Players />
		</Route>
		<Route path="/latest">
		<UploadIndex />
		</Route>
		<Route path="/aggregate/:modSum">
		<Aggregate />
		</Route>
		<Route exact path="/">
		<Heading level="3">About</Heading>
		<Box border={{size: "small"}} pad="medium">
		<Box>
		<p>The Total Annihilation Demo Archive is a place for players of the game <Anchor href="https://tauniverse.com/" label="Total Annihilation" /> to browse and share recorded matches. It contains tools for the analysis of recorded matches such as unit count aggregations, gameplay visualizations, income charts, and scoreboards.</p>
		</Box>
		</Box>
		<UploadIndex />
		</Route>
		</Main>
		</Grommet>
		</Router>
	);
}

function Header() {
	return (
		<Box direction="row" justify="between" background="dark-2">
			<Box margin={{horizontal: "small"}}>
			<Anchor style={{textDecoration: "none"}} href="/">
			<Heading level="2" style={{marginTop: ".25em", marginBottom: ".25em"}}>
			tada
			</Heading>
			</Anchor>
			<Heading level="1">We have moved! Please visit <Anchor href="https://tademos.xyz/" label="tademos.xyz" /> for demo uploads.</Heading>
			</Box>
			<Box direction="row" align="center" gap="small" margin={{horizontal: "small"}}>
			<Anchor label="Latest" href="/latest" />
			<Anchor label="Players" href="/players" />
			<Anchor label="Maps" href="/maps" />
			<Anchor label="Upload" href="/upload" />
			<Anchor href="https://www.fnta.xyz" label="Donate"/>
			</Box>
		</Box>
	);
}

export default App;
