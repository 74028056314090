// mods file
export const loadedMods = {
    "0123": {"modName":"Esc 9.8.6","checksum":"0123","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "b2e1": {"modName":"ESC 9.8.5","checksum":"b2e1","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "0cfc": {"modName":"ProTA 4.3","checksum":"0cfc","ext":"pro","features":["unitname","name","buildcostmetal"]},
    "cb9e": {"modName":"ESC 9.7","checksum":"cb9e","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "36c6": {"modName":"ESC 9.6","checksum":"36c6","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "2eef": {"modName":"ESC 9.5","checksum":"2eef","ext":"ted"},
    "755b": {"modName":"ESC 9.4","checksum":"755b","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "5f12": {"modName":"ESC 9.3","checksum":"5f12","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "8da2": {"modName":"ESC 9.2","checksum":"b21b","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "b21b": {"modName":"ESC 9.2","checksum":"b21b","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "4184": {"modName":"ESC 9.1","checksum":"4184","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "f01d": {"modName":"ESC 9.0","checksum":"f01d","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "0087": {"modName":"ESC 8.9","checksum":"0087","ext":"ted","features":["unitname","name","buildcostmetal"]},
    "bd2f": {"modName":"OTA","checksum":"bd2f","ext":"tad","features":["unitname"]},
    "a9a5": {"modName":"TM 8.1.5","checksum":"a9a5","ext":"tmd"},
    "7c53": {"modName":"TM 7c53","checksum":"7c53","ext":"tmd"},
    "f60d": {"modName":"TM f60d","checksum":"f60d","ext":"tmd"},
    "1631": {"modName":"TM 1631","checksum":"1631","ext":"tmd"}
}
