import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import { Anchor, Box, DataTable, Heading, Image, Select } from 'grommet';
import ReactCountryFlag from 'react-country-flag';
import axios from 'axios';
import BarLoader from 'react-spinners/BarLoader';
import { loadedMods } from './Mods';
import { tadaApi } from './App';
function Players() {
	return (
		<Router>
		<Route exact path="/players/:player">
		<GamesByMod />
		</Route>
		<Route path="/players/:player/:mod">
		<GamesList />
		</Route>
		<Route exact path="/players">
		<PlayerSelector />
		</Route>
		</Router>
	);
}
function SearchSelect({defaultOptions, callback}) {
	const [options, setOptions] = useState(defaultOptions);
	const [value, setValue] = useState();
	const changeValue = (option) => {
		setValue(option);
		callback(option);
	};
	return (
		<Box size="medium">
		<Select
		placeholder="Select Player"
		value={value}
		options={options}
		onChange={({ option }) => changeValue(option)}
		onClose={() => setOptions(defaultOptions)}
		onSearch={text => {
			// The line below escapes regular expression special characters:
			// [ \ ^ $ . | ? * + ( )
			const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

			// Create the regular expression with modified value which
			// handles escaping special characters. Without escaping special
			// characters, errors will appear in the console
			const exp = new RegExp(escapedText, "i");
			setOptions(defaultOptions.filter(o => exp.test(o)));
		}}
		/>
		</Box>
	);
}
function PlayerSelector() {
	const [data, setData] = useState({});
	const [player, setPlayer] = useState();
	const [loaded] = useState(true);
	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(`${tadaApi.players}?t=names`);
			setData(result.data);
		};
		fetchData();
	}, [loaded]);
	if(!Array.isArray(data)) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	return (
		<Box>
		<Box gap="small" direction="row" align="center" >
		<Heading level="3">List Games by Player</Heading>
		<SearchSelect defaultOptions={data} callback={setPlayer}/>
		</Box>
		<GamesByMod playerProp={player}/>
		</Box>
	);
}
function GamesByMod({playerProp}) {
	const { player} = useParams();
	const [data, setData] = useState()
	useEffect(() => {
		const fetchData = async () => {
			let playerName = hexToAscii(player);
			if(playerProp) {
				playerName = playerProp;
			}
			const result = await axios(`${tadaApi.players}?t=games&n=${asciiToHex(playerName)}`);
			setData(result.data);
		};
		fetchData();
	}, [player, playerProp]);
	if(!player && !playerProp) {
		return "No player selected.";
	}
	let playerName = hexToAscii(player);
	if(playerProp) {
		playerName = playerProp;
	}
	if(!data) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	const tableData = Object.keys(data).map(e => {
		const vers = e.slice(0, 4);
		let modName = vers;
		if(loadedMods[vers]) {
			modName = loadedMods[vers].modName;
		}

		return {
			vers,
			fullVers: e,
			modName,
			count: data[e]
		};
	});
	const columns = [
		{
			"property": "modName",
			"header": "Mod",
			"primary": true,
		},
		{
			"property": "count",
			"header": "No. of Games",
			"render": (e) => (
				<Anchor label={e.count} href={`/players/${asciiToHex(playerName)}/${e.fullVers}`} />
			),
		}
	];
	return (
		<Box width="medium">
		<Heading level="3">{playerName}</Heading>
		<DataTable
		columns={columns}
		data={tableData}
		/>
		</Box>

	);
}
export function asciiToHex(str) {
	if(!str) {
		return "";
	}
	let result = [];
	for(let i = 0; i < str.length; i++) {
		let hex = Number(str.charCodeAt(i)).toString(16);
		result.push(hex);
	}
	return result.join('');
}
export function hexToAscii(hex) {
	if(!hex) {
		return "";
	}
	let result = ""
	for(let i = 0; i < hex.length; i += 2) {
		result += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
	}
	return result;
}
function GamesList() {
	const { player, mod } = useParams();
	const [data, setData] = useState()
	useEffect(() => {
		const fetchData = async () => {
			const name = hexToAscii(player);
			const result = await axios(`${tadaApi.players}?t=games&n=${asciiToHex(name)}&v=${mod}`);
			setData(result.data);
		};
		fetchData();
	}, [player, mod]);
	if(!player) {
		return "No player found";
	}
	if(!data) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	const columns = [
		{
			"property": "sorty",
			"header": "Recorded",
			"primary": true,
			"render": (e) => {
				const [recDate, gameLink] = e.sorty.split("#");
				const d = new Date(recDate.split(" ")[0]);
				return <Anchor label={d.toDateString()} href={`/games/${gameLink}`} />;
			},

		},
		{
			"property": "mapName",
			"header": "Map",
			"search": true,
		},
		{
			"property": "otherPlayers",
			"header": "Players",
			"search": true,
			"render": (e) => e.otherPlayers.filter(p => p !== e.name).join(", "),
		},
		{
			"property": "side",
			"header": "Side",
			"render": (e) => (
				<Box direction="row" align="center" gap="xsmall" >
					<Image
					style={{ width: "16px", height: "16px" }}
					src={"/logos/logo" + (e.color +1) + ".bmp"} />
					{e.side}
				</Box>
			),

		},
		{
			"property": "country",
			"header": "Country",
			"render": (e) => <ReactCountryFlag countryCode={e.country} svg/>,
		},
	];
	let modName = mod.slice(0,4);
	if(loadedMods[modName]) {
		modName = loadedMods[modName].modName;
	}
	const playerName = hexToAscii(player);
	return (
		<Box>
		<Heading level="3">{`${playerName}'s ${modName}`} Matches</Heading>
		<DataTable
		columns={columns}
		data={data}
		/>
		</Box>
	);
}
export default Players;
