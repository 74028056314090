import React from 'react';
import { tadaApi } from './App';
import { Box, Button, Form, Heading, Meter } from 'grommet';
class Upload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			uploadUrl: "",
			value: "",
			isUploading: false,
			lastUpload: null,
			uploadedValue: 0
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.fileInput = React.createRef();
	}
	handleChange(e) {
		this.setState({value: e.target.value});
	}
	handleSubmit(e) {
		e.preventDefault();
		let f = this.fileInput.current.files[0];
		this.setState({isUploading: true}, () => this.uploadFile(f));
	}
	componentDidMount() {
		this.updateUrl()
	}
	updateUrl() {
		fetch(tadaApi.upload).then(r => r.json()).then(r => this.setState({uploadUrl: r.signedUrl}));
	}
	uploadFile(file) {
		const reader = new FileReader();
		const xhr = new XMLHttpRequest();
		this.xhr = xhr;
		const self = this;
		this.xhr.upload.addEventListener("progress", function(e) {
			if(e.lengthComputable) {
				self.setState({uploadedValue: Math.round((e.loaded * 100) / e.total)});
			}
		}, false);
		xhr.upload.addEventListener("load", function(e) {
			self.setState({
				uploadedValue: 0,
				isUploading: false,
				lastUpload: file.name + " uploaded successfully"
			})
		}, false);
		xhr.overrideMimeType(file.type);
		xhr.open("PUT", self.state.uploadUrl);
		reader.onload = function(evt) {
			xhr.send(evt.target.result);
		};
		reader.readAsArrayBuffer(file);
	}
	render() {
		return (
			<Box gap="small">
			<Heading level="3">Upload Demo</Heading>
			<Heading level="1">Please go to the new site to upload demos: tademos.xyz</Heading>
			</Box>
		);
		return (
			<Box gap="small">
			<Heading level="3">Upload Demo</Heading>
			<Form onSubmit={this.handleSubmit}>
			<input 
			ref={this.fileInput} 
			name="file" 
			type="file" 
			label="Select demo to upload" 
			required 
			/>
			<br />
			<br />
			<Button
			disabled={this.state.isUploading}
			type="submit" 
			primary 
			label="Submit" 
			/>
			{this.state.isUploading ? (
				<Box>
				uploading...
				<Meter
				type="bar"
				values={[{
					value: this.state.uploadedValue,
					onClick: () => {}
				}]}
				aria-label="meter"
				/>
				</Box>
			) : (
				<p>{this.state.lastUpload}</p>
			)}
			</Form>
			</Box>
		);
	}
}
export default Upload;
