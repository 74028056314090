import React from 'react'

import { GifReader } from 'omggif';
class GifPlayer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            paused: true
        }
        this._speed = 1
        this._repeat = true
        this._reset()
        
        this._canvas = React.createRef()
    }
    componentDidMount() {
        
        
        this._ctx = this._canvas.current.getContext('2d')
        this.load(this.props.src)
    }
    
    _reset() {
        this._frames = [];
        this._delays = [];
        this._frame = 0;
        this._decoded = -1;
        this._rendered = -1;    // frame last rendered
    }
    load(src) {
        this._reset()
        var options = {
            method: 'GET',
            mode: 'cors',
            cache: 'default'
        };

        fetch(src, options)
            .then(resp => resp.arrayBuffer())
            .then(buf => new Uint8Array(buf))
            .then(buf => new GifReader(buf))
            .then(gif => this.process(gif))
	    .catch(e => {
		    console.log(e);
	    })
    }
    process(gif) {
        this._gif = gif

        // canvas drawing area always matches the gif size
        this._canvas.current.width = gif.width
        this._canvas.current.height = gif.height

        var count = gif.numFrames()
        
        this._decoded = -1
        this._delays = new Array(count)
        this._frames = new Array(count)

        if (this._frame < 0) {
            this._frame = this._frames.length + this._frame
        }

        // var e = new CustomEvent('gif-loaded', { bubbles: true, composed: true, detail: gif })
        // this.dispatchEvent(e)
    
        this.start()
    }
    start() {            
        this.playing = true
        this.playAnimation(this._frames, this._frame)
    }
    get frame() { return this._frame; }
    set frame(val) {
        this._frame = parseInt(val);
        this.displayFrame(this._frames, this._frame)
	if (this.props.frameCallback) this.props.frameCallback(this._frames.length, this._frame);
    }
    
    displayFrame(frames, frame) {
        if (frames.length === 0) return;
        if (frame >= frames.length) frame = frames.length - 1;
        if (frame < 0) frame += frames.length;

        this.renderFrame(frame);

        if (this._rendered !== frame) {
        requestAnimationFrame(() => {
            this._ctx.putImageData(this._frames[frame], 0, 0);
            this._rendered = frame;
        });
        }
    }
    playAnimation(frames, frame) {
        if (frames.length === 0) {
            return
        }
        this.renderFrame(frame)
        setTimeout(() => {
            if (this.state.paused) {
                return
            }
            // frame++ or frame + _direction
            var frame = this.frame + 1
            if (frame >= this._frames.length) {
                frame = 0
            }
            this.frame = frame;
            this.playAnimation(this._frames, this._frame)
        }, this._delays[frame] * (1 / this._speed))
    }
    renderFrame(frame) {
        
        while (this._decoded < frame) {
            var curr = this._decoded + 1
            var frameInfo = this._gif.frameInfo(curr)
            var imageData = this._ctx.createImageData(this._gif.width, this._gif.height)
            if (curr > 0 && frameInfo.disposal < 2) {
                imageData.data.set(new Uint8ClampedArray(this._frames[curr - 1].data))
            }
            this._gif.decodeAndBlitFrameRGBA(curr, imageData.data)
            this._frames[curr] = imageData
            this._delays[curr] = frameInfo.delay * 10
            this._decoded = curr
        }
    }
    togglePlayback(e) {
        if(!this.state.paused) {
            this.setState({paused: true})
            return
        }
        this.setState({paused: false})
        this.playAnimation(this._frames, this._frame);
    }
    move(e) {
        e.preventDefault();

        var clientX;
        if (e.targetTouches) {
        clientX = e.targetTouches[0].clientX;
        } else {
        clientX = e.clientX;
        }

        // calculate our relative horizontal position over the element
        // TODO: cache this, clear on scroll / resize etc...
        var rect = this._canvas.current.getBoundingClientRect();
        var x = clientX - rect.left;
        var position = x / rect.width;

        // ... and which frame should appear there
        this.frame = Math.round((this._frames.length - 1) * position);
    }
    render() {
	if(this.props.speedCallback) {
		const newSpeed = this.props.speedCallback()
		if(newSpeed !== this._speed && newSpeed !== 0) this._speed = newSpeed;
	}
        return (
            <canvas ref={this._canvas} onClick={(e) => this.togglePlayback(e)} onMouseMove={(e) => this.move(e)}/>
        )
    }
}

export default GifPlayer
