import React, { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { Box, Drop, DropButton, Image, Heading, DataTable, Text } from 'grommet';
import { useParams } from 'react-router-dom';
import { loadedMods } from './Mods';
// import { units92 } from './8da2-agg';
import { unitsProcessed92 } from './esc92-20200414';
import axios from 'axios';
const HRNumbers = require('human-readable-numbers');
const pretty = require('pretty-time');
function Aggregate() {
	const [unitMapping, setUnitMapping] = useState(null);
	const { modSum } = useParams();
	const c4 = loadedMods[modSum].checksum;
	const manifest = loadedMods[c4];
	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(`/mods/${c4}/units.json`);
			setUnitMapping(result.data);
		};
		fetchData();
	}, [c4]);
	if(!unitMapping) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	// const rawUnits = processUnits(unitMapping, units92);
	// const units = rawUnits.map(e => {
	// 	let avgFirstProduced = 0;
	// 	let avgDmgDealt = 0;
	// 	let avgDmgReceived = 0;
	// 	let avgKills = 0;
	// 	let avgDeaths = 0;
	// 	let avgKD = 0;
	// 	let tmpDeathsValue = 1;
	// 	if(e.records !== 0) {
	// 		avgFirstProduced = Math.ceil(e.firstProduced / e.records);
	// 		avgDmgDealt = Math.ceil(e.dmgDealt / e.records);
	// 		avgDmgReceived = Math.ceil(e.dmgReceived / e.records);
	// 		if(e.kills !== null) {
	// 			avgKills = Math.ceil(Object.values(e.kills).reduce((r, m) => m + r, 0) / e.records);
	// 		}
	// 		if(e.deaths !== null) {
	// 			avgDeaths = Math.ceil(Object.values(e.deaths).reduce((r, m) => m + r, 0) / e.records);
	// 		}
	// 		if(avgDeaths !== 0) {
	// 			tmpDeathsValue = avgDeaths;
	// 		}
	// 		avgKD = avgKills / tmpDeathsValue;
	// 	}
	// 	return {
	// 		...e,
	// 		avgFirstProduced,
	// 		avgDmgDealt,
	// 		avgDmgReceived,
	// 		avgKills,
	// 		avgDeaths,
	// 		avgKD
	// 	};
	// });
	// return JSON.stringify(units);
	const units = unitsProcessed92;
	const columns = [
		{
			"property": "unitname",
			"primary": true,
			"header": "Name",
			"search": true,
			"render": (e) => (
				<Box direction="row" gap="small">
				<UnitDropButton unit={e} modSum={c4} units={units}/>
				<Box>
				<Text weight="bold">{e.name}</Text>
				<Text>{e.unitname}</Text>
				</Box>
				</Box>
			),
		},
		{
			"property": "records",
			"header": "Players Used",
		},
		{
			"property": "avgFirstProduced",
			"header": "Avg. First Produced",
			"render": (e) => {
				if(e.avgFirstProduced === 0) {
					return "Unused";
				}
				return pretty([
					0,
					Math.ceil(e.avgFirstProduced) * 1e6
				], "s");
			},
		},
		{
			"property": "avgKD",
			"header": "Avg. K/D",
			"render": (e) => e.avgKD.toFixed(2),
		},
		{
			"property": "avgDmgDealt",
			"header": "Avg. Damage Dealt",
			"render": (e) => HRNumbers.toHumanString(e.avgDmgDealt),
		},
		{
			"property": "produced",
			"header": "Total Produced",
		}


	];
	return (
		<Box>
		<Heading level="3">{manifest.modName} 2020-04-14</Heading>
		<DataTable
		size="large"
		columns={columns}
		data={units}
		sortable
		/>
		</Box>
	);
}
		
const UnitDropContent = ({unit, onClose, units, modSum}) => (
	<Box pad="small" gap="small" direction="row">
	<Box>
	Name: {unit.name}<br />
	Unitname: {unit.unitname}<br />
	Players Used: {unit.records}<br />
	Avg. Damage Dealt: {unit.avgDmgDealt}<br />
	Avg. Damage Received: {unit.avgDmgReceived}<br />
	Avg. First Produced: {unit.avgFirstProduced === 0 ? "Unused" : pretty([0, Math.ceil(unit.avgFirstProduced*1e6)], "s")}<br />
	Avg. K/D: {unit.avgKD.toFixed(2)}<br />
	Avg. Kills: {unit.avgKills}<br />
	Avg. Deaths: {unit.avgDeaths}<br />
	</Box>
	<Box>
	Kills
	<Box direction="row" width="large" wrap>
	{unit.kills ? 
	Object.keys(unit.kills).sort(function(a, b) { return unit.kills[b] - unit.kills[a]}).map(e => (
		<UnitKDItem 
		key={e}
		imgLink={"/mods/"+modSum+"/unitpic/"+units[parseInt(e)-1].unitname.toLowerCase()+".pcx.png"}
		unitname={units[parseInt(e)-1].unitname}
		name={units[parseInt(e)-1].name}
		count={unit.kills[e]}
		/>
	))
	: null}
	</Box>
	</Box>
	<Box>
	Deaths
	<Box direction="row" width="large" wrap>
	{unit.deaths ? 
	Object.keys(unit.deaths).sort(function(a, b) { return unit.deaths[b] - unit.deaths[a]}).map(e => (
		<UnitKDItem 
		key={e}
		imgLink={"/mods/"+modSum+"/unitpic/"+units[parseInt(e)-1].unitname.toLowerCase()+".pcx.png"}
		unitname={units[parseInt(e)-1].unitname}
		name={units[parseInt(e)-1].name}
		count={unit.deaths[e]}
		/>
	))
	: null}
	</Box>
	</Box>
	</Box>
);
	
class UnitKDItem extends React.Component {
	state = {};
	ref = React.createRef();
	render() {
		const { over } = this.state;
		return (
			<Box ref={this.ref} margin="xsmall">
			<Image
			style={{width: "32px", height: "32px"}}
			onMouseEnter={() => this.setState({over: true})}
			onMouseOut={() => this.setState({over: false})}
			src={this.props.imgLink}
			/>
			{this.ref.current && over && (
				<Drop
				align={{bottom: "top"}}
				target={this.ref.current}
				plain
				>
				<Box
				pad="small"
				border={{color: "#C8D0D2"}}
				background="#1D1F1F"
				>
				{this.props.name}<br />
				{this.props.unitname}<br />
				{this.props.count}
				</Box>
				</Drop>
			)}
			</Box>
		);
	}
}
function UnitDropButton({modSum, unit, units}) {
	const [open, setOpen] = useState();
	const onOpen = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};
	return (
		<Box>
		<DropButton
		onOpen={onOpen}
		onClose={onClose}
		dropContent={<UnitDropContent unit={unit} onClose={onClose} units={units} modSum={modSum}/>}
		dropProps={{ align: { left: "right" } }}
		>
		<Image
		src={"/mods/"+modSum+"/unitpic/"+unit.unitname.toLowerCase()+".pcx.png"}
		style={{width: "72px", height: "72px"}}
		/>
		</DropButton>
		</Box>
	);
}
function processUnits(unitMapping, unitRecords) {
	let result = [];
	for(const unit in unitMapping) {
		result = result.concat({
			name: unitMapping[unit].name,
			unitname: unitMapping[unit].unitname,
			produced: 0,
			firstProduced: 0,
			dmgDealt: 0,
			dmgReceived: 0,
			records: 0
		});
	}
	const loadRecord = (index, record) => {
		const uindex = parseInt(index)-1;
		let newKills = {};
		if(result[uindex].kills) {
			newKills = result[uindex].kills;
		}
		if(record["Kills"]) {
			Object.keys(record["Kills"]).forEach(e => {
				if(newKills[e]) {
					newKills[e] += record["Kills"][e];
				} else {
					newKills[e] = record["Kills"][e];
				}
			});
		}
		let newDeaths = {};
		if(result[uindex].deaths) {
			newDeaths = result[uindex].deaths;
		}
		if(record["Deaths"]) {
			Object.keys(record["Deaths"]).forEach(e => {
				if(newDeaths[e]) {
					newDeaths[e] += record["Deaths"][e];
				} else {
					newDeaths[e] = record["Deaths"][e];
				}
			});
		}
		result[uindex] = {
			name: result[uindex].name,
			unitname: result[uindex].unitname,
			produced: result[uindex].produced + record["Produced"],
			firstProduced: result[uindex].firstProduced + record["FirstProduced"],
			dmgDealt: result[uindex].dmgDealt + record["DamageDealt"],
			dmgReceived: result[uindex].dmgReceived + record["DamageReceived"],
			records: result[uindex].records + 1,
			kills: newKills,
			deaths: newDeaths
		};
	};
	for(const row in unitRecords) {
		const units = Object.keys(unitRecords[row]);
		for(const i in units) {
			loadRecord(units[i], unitRecords[row][units[i]]);
		}
	}
	return result;
}
export default Aggregate;
