import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Anchor, Box, Heading, Menu, Select } from 'grommet';
import { tadaApi } from './App';
import BarLoader from 'react-spinners/BarLoader';
import InfiniteScroll from 'react-infinite-scroller';
import { loadedMods } from './Mods';
const pretty = require('pretty-time');

function tadaQuery({endpoint, order = "Uploaded", map, lastKey}) {
	let result = endpoint + "?order=" + order;
	if(lastKey) {
		result += "&lastKey=" + lastKey;
	}
	if(map) {
		result += "&m=gameson&mapName=" + map;
	}
	return result;
}
function Index({endpoint, seek, order, map}) {
	const [url, setUrl] = useState(tadaQuery({endpoint, order, map}));
	const [data, dispatch] = useReducer(scrollReducer, {});
	const [isLoading, setIsLoading] = useState(false);
	if(seek !== "" && url !== tadaQuery({endpoint, order, lastKey: seek})) { 
		setUrl(tadaQuery({endpoint, order, lastKey: seek})); 
	}
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const result = await axios(url);
			dispatch({payload: result.data, type: "init"});
			setIsLoading(false);
		};
		fetchData();
	}, [url]);
	if(isLoading || !data.games) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	const fetchMore = (lastKey, page) => {
		axios.get(tadaQuery({endpoint, order, lastKey, map})).then(result =>{
			dispatch({payload: result.data, page: page, type: "more"});
		});
	};

	const cards = data.games.map(e => <GameCard game={e} key={e.party} />);
	return (
		<Box gap="small">	
		<InfiniteScroll
		pageStart={0}
		loadMore={(page) => fetchMore(data.lastKey, page)}
		hasMore={data.hasMore !== false}
		loader={<BarLoader color="#C8D0D2" key={0} loading={true}/>}
		>
		{cards}
		</InfiniteScroll>
		</Box>
	);
}
Index.defaultProps = {
	seek: "",
	last: "",
	order: "Uploaded"
};
function scrollReducer(state, action) {
	switch(action.type) {
		case "init":
			action.payload.games.sort(function(a, b) {
				let at = new Date(a.uploaded);
				let bt = new Date(b.uploaded);
				return bt - at;
			});
			return {
				games: action.payload.games,
				lastKey: action.payload.lastKey,
				hasMore: action.payload.lastKey || false,
				page: 0
			};
		case "more":
			if(action.page === state.page) {
				return state;
			}
			action.payload.games.sort(function(a, b) {
				let at = new Date(a.uploaded);
				let bt = new Date(b.uploaded);
				return bt - at;
			});
			return {
				games: state.games.concat(action.payload.games),
				lastKey: action.payload.lastKey,
				hasMore: action.payload.lastKey || false,
				page: action.page
			};
		default:
			throw new Error("unexpected action type");
	}
}

function GameCard({game}) {
	let d = new Date(game.uploaded);
	let p = getPlayers(game.players).join(", ");
	let vers = game.unitChecksum.slice(0,4);
	if(loadedMods[vers]) {
		vers = loadedMods[vers].modName;
	}
	const title = d.toDateString() + " - " + game.mapName;
	const list = [
		{ n: 'Recorded', v: (new Date(game.date)).toLocaleDateString() },
		{ n: 'Version', v: vers },
		{ n: 'Length', v: pretty([0, game.length], "m")},
		{ n: 'Players', v: game.numPlayed},
		{ n: 'Downloads', v: game.downloads}
	];
	return (
		<Box border={{size: "small"}} pad="medium" margin={{bottom: "small"}} wrap direction="row" align="center">
		<Box direction="column"><Heading style={{marginBottom: "0px"}} level="3"><Anchor href={"/games/" + game.party}>{title}</Anchor></Heading><Heading style={{marginTop: "0px"}} level="4">{p}</Heading></Box>
		<Box>
		<ul style={{listStyleType: "none"}}>
		{list.map(e => <li key={e.n}>{e.n}: {e.v}</li>)}
		</ul>
		</Box>
		</Box>
	);
}
export function getPlayers(players) {
	const result = players.filter(e => e.side !== "WATCH").map(e => e.name);
	return result;
}
export function UploadIndex() {
	const [lastKey, setLastKey] = useState("");
	const months = datesAfterFeb2019().map(e => {
		e.onClick = () => setLastKey(e.value);
		return e;
	});
	return (
		<>
		<Heading level={3}>
		Uploads
		<Menu label="Month" items={months} />
		</Heading>
		<Index endpoint={tadaApi.games} seek={lastKey}/>
		</>
	);
}
export function MapList() {
	const { mapName } = useParams();
	const [data, setData] = useState({});
	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(`${tadaApi.maps}?m=list`);
			setData(result.data);
		};
		fetchData();
	});
	if(!Array.isArray(data)) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	const options = data.filter(m => m.gamesOnCount > 0).sort((a, b) => b.gamesOnCount - a.gamesOnCount).map(e => e.sorty);
	if(!mapName) {
		return (
			<Box gap="small" direction="row" align="center" >
			<Heading level="3">Map Search</Heading>
			<SearchSelect defaultOptions={options} />
			</Box>
		);
	}
	return (
		<>
		<Box gap="small" direction="row" align="center" >
		<Heading level="3">Map Search</Heading>
		<SearchSelect defaultOptions={options} map={mapName}/>
		</Box>
		<Index endpoint={tadaApi.maps} map={mapName} />
		</>
	);
}
const SearchSelect = ({defaultOptions, map}) => {
	const [options, setOptions] = useState(defaultOptions);
	const [value] = useState(map);
	const change = (mapName) => {
		window.location = "/maps/" + mapName;
		return;
	}
	return (
		<Box size="medium">
		<Select
		placeholder="Select Map"
		value={value}
		options={options}
		onChange={({ option }) => change(option)}
		onClose={() => setOptions(defaultOptions)}
		onSearch={text => {
			// The line below escapes regular expression special characters:
			// [ \ ^ $ . | ? * + ( )
			const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

			// Create the regular expression with modified value which
			// handles escaping special characters. Without escaping special
			// characters, errors will appear in the console
			const exp = new RegExp(escapedText, "i");
			setOptions(defaultOptions.filter(o => exp.test(o)));
		}}
		/>
		</Box>
	);
};
SearchSelect.defaultProps = {
	defaultOptions: [],
	map: ""
};
function datesAfterFeb2019() {
	let now = new Date();
	const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July ',
	'August',
	'September',
	'October',
	'November',
	'December'
	];
	now.setHours(0, 0, 0, 0);
	now.setDate(1);
	let result = [];
	while(now.getFullYear() >= 2019) {
		if(now.getMonth() <= 1 && now.getFullYear() === 2019) {
			break;
		}
		let val = now.toISOString().split("T")[0];
		if(now.getMonth() === 0) {
			now.setMonth(11);
			now.setFullYear(now.getFullYear()-1);
		} else {
			now.setMonth(now.getMonth()-1);
		}
		result = result.concat({
			value: val, 
			label: months[now.getMonth()] + " " + now.getFullYear()
		});
	}
	return result;
}

