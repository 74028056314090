import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import BarLoader from 'react-spinners/BarLoader';
import { Accordion, AccordionPanel, Anchor, Box, CheckBox, Drop, Heading, Image, Menu, RangeInput, Stack, Table, TableBody, TableRow, TableCell, Text } from 'grommet';
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Line, Legend, ResponsiveContainer } from 'recharts';
import { useParams } from 'react-router-dom';
import ReactCountryFlag from 'react-country-flag';
import { tadaApi } from './App';
import { loadedMods } from './Mods';
import { asciiToHex } from './Players';
import GifPlayer from './GifPlayer';
const pretty = require('pretty-time');
const HRNumbers = require('human-readable-numbers');
function Demo() {
	const { gameId } = useParams();
	const [data, setData] = useState({});
	const [chartData, setChartData] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(`${tadaApi.games}/${gameId}`);
			setData(result.data);
		};
		fetchData();
	}, [gameId]);

	if(!data.party) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	let d = new Date(data.date);
	const title = d.toLocaleDateString() + " - " + data.mapName;
	return (
		<Box>
		<Box overflow="auto"> 
		<Heading level="3">{title}</Heading>
		<Summary
		party={data.party}
		date={data.date}
		mapName={data.mapName}
		unitChecksum={data.unitChecksum}
		length={data.length}
		players={data.players}
		/>
		</Box>
		<Accordion multiple>
		<AccordionPanel label="Scores">
		<Scores players={data.players} finalScores={data.finalScores} seriesData={chartData} />
		</AccordionPanel>
		<AccordionPanel label="Chart">
		<Chart gameId={gameId} dataCallback={setChartData} />
		</AccordionPanel>
		{data.gifLink720 ? (
			<AccordionPanel label="Playback">
			<Playback 
			gameId={gameId} 
			players={data.players.filter(e => e.side !== "WATCH")} 
			imgLink720={data.imgLink720}
			gifLink720={data.gifLink720}
			/>
			</AccordionPanel>
		) : null}
		{loadedMods[data.unitChecksum.slice(0,4)] && loadedMods[data.unitChecksum.slice(0,4)].features ? (
			<AccordionPanel label="Units">
			<Units 
			gameId={gameId}
			vers={data.unitChecksum}
			players={data.players.filter(e => e.side !== "WATCH")}
			/>
			</AccordionPanel>
		) : null}
		</Accordion>
		</Box>
	);
}
function Summary({party, date, mapName, unitChecksum, length, players}) {
	const d = new Date(date);
	const active = players.filter(e => e.side !== "WATCH");
	const watchers = players.filter(e => e.side === "WATCH");
	const version = (chk) => {
		if(loadedMods[chk]) {
			return loadedMods[chk].modName;
		}
		return chk;
	};
	const getDemo = async (filename) => {
		const linkResult = await axios(`${tadaApi.download}/?id=${party}`);
		fetch(linkResult.data.signedUrl).then(r => r.blob()).then(demo => {
			const a = document.createElement("a");
			a.href = window.URL.createObjectURL(demo);
			a.download = filename;
			a.dispatchEvent(new MouseEvent("click"));
		});
	};
	const download = (date, mapName, active, unitChecksum) => {
		let front = d.toISOString().split("T")[0];
		let chk = unitChecksum.slice(0,4);
		let ext = "ted";
		if(loadedMods[chk]) {
			ext = loadedMods[chk].ext;
		}
		const name = [front, mapName, active.map(e => e.name).join(", ")].join(" - ") + "." + ext;
		return (
			<Anchor 
			label={name}
			href="#download"
			onClick={() => getDemo(name)}
			/>
		);
	};
	return (
		<Table>
		<TableBody>
		<TableRow>
		<TableCell>Download</TableCell>
		<TableCell>{download(d, mapName, active, unitChecksum)}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Recorded</TableCell>
		<TableCell>{d.toLocaleDateString()}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Map</TableCell>
		<TableCell>{mapName}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Version</TableCell>
		<TableCell>{version(unitChecksum.slice(0,4))}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Length</TableCell>
		<TableCell>{pretty([0, length], "m")}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Players</TableCell>
		<TableCell>
			<Box direction="row">
			<Box>
			{active.map(e => <span key={e.id}>
				<Anchor label={e.name} href={`/players/${asciiToHex(e.name)}`}/> <ReactCountryFlag countryCode={e.country} svg/> {e.side}
				</span>
			)}
			</Box>
			</Box>
		</TableCell>
		</TableRow>
		{watchers.length !== 0 ? (
			<TableRow>
			<TableCell>Watchers</TableCell>
			<TableCell>
				<Box direction="row">
				<Box>
				{watchers.map(e => <span key={e.id}>
					{e.name}
					</span>
				)}
				</Box>
				</Box>
			</TableCell>
			</TableRow>
		) : null}
		</TableBody>
		</Table>
	);
}
class MvpItem extends React.Component {
	state = {};
	ref = React.createRef();
	render() {
		let imgComponent;
		switch(this.props.place) {
			case 1:
				imgComponent = (
					<Image
					style={{width: "32px", height: "32px"}} 
					src={`/logos/mvp1.gif`} 
					onMouseEnter={() => this.setState({over: true})}
					onMouseOut={() => this.setState({over: false})}
					/>
				);
				break;
			case 2:
				imgComponent = (
					<Image
					style={{width: "32px", height: "32px"}} 
					src={`/logos/mvp2.png`} 
					onMouseEnter={() => this.setState({over: true})}
					onMouseOut={() => this.setState({over: false})}
					/>
				);
				break;
			case 3:
				imgComponent = (
					<Image
					style={{width: "32px", height: "32px"}} 
					src={`/logos/mvp3.png`} 
					onMouseEnter={() => this.setState({over: true})}
					onMouseOut={() => this.setState({over: false})}
					/>
				);
				break;
			default:
				imgComponent = (
					<Box 
					background="brand"
					pad="xsmall"
					round
					onMouseEnter={() => this.setState({over: true})}
					onMouseOut={() => this.setState({over: false})}
					/>
				);
		}
		const { over } = this.state;
		return (
			<Box ref={this.ref}>
			{imgComponent}
			{this.ref.current && over && (
				<Drop
				align={{left: "right"}}
				target={this.ref.current}
				plain
				>
				<Box
				pad="small"
				border={{color: "#C8D0D2"}}
				background="#1D1F1F"
				>
				{this.props.info}
				</Box>
				</Drop>
			)}
			</Box>
		);
	}
}
function divide(a, b) {
	return a / b;
}
function calculateMvp(finalScores, seriesData, maxes) {
	const players = finalScores.map(p => p.player);
	let mvp = {};
	let raw = {};
	let sorted = [];
	let sk = {};
	let skScores = {};
	sk["0"] = {};
	players.forEach(p => {
		sk["0"][p] = 0;
		skScores[p] = 0;
	});
	finalScores.forEach(r => {
		raw[r.player] = {};
		raw[r.player].kills = divide(r.kills, maxes.kills);
		raw[r.player].energy = divide(r.energyProduced, maxes.energyProduced);
		raw[r.player].metal = divide(r.metalProduced, maxes.metalProduced);
		raw[r.player].excessE = 0.5 * divide(r.excessEnergy, r.energyProduced);
		raw[r.player].excessM = 0.8 * divide(r.excessMetal, r.metalProduced);
		raw[r.player].total = raw[r.player].kills + raw[r.player].energy + raw[r.player].metal - raw[r.player].excessE - raw[r.player].excessM;
	});

	let killSeries = Object.keys(seriesData).filter(e => e.indexOf("SeriesKills") === 0);
	killSeries.forEach(s => {
		seriesData[s].data.forEach(e => {
			if(!sk[e[0]]) {
				sk[e[0]] = {};
			}
			let p = s.substr("SeriesKills".length, s.length);
			sk[e[0]][p] = e[1];
		});
	});
	sk = Object.keys(sk).map(e => {
		return {
			values: sk[e],
			seconds: e
		};
	});
	sk.sort(function(a, b) { return a.seconds - b.seconds });
	for(let i = 0; i < sk.length; i++) {
		let keys = Object.keys(sk[i].values);
		players.forEach(p => {
			if(sk[i] && !keys.includes(p)) {
				sk[i].values[p] = sk[i-1].values[p];
			}
		});
		let vs = Object.keys(sk[i].values).map(e => {
			return {
				player: e,
				value: sk[i].values[e]
			};
		});
		vs.sort(function(a, b) { return b.value - a.value });
		for(let j = 0; j < vs.length; j++) {
			// top kills bonus
			if(j === 0) {
				skScores[vs[j].player] += vs.length;
			}
			skScores[vs[j].player] += vs.length - j;
		}
	}
	const skMax = Math.max.apply(Math, Object.values(skScores));
	players.forEach(e => {
		let skVal = 1.5 * divide(skScores[e], skMax);
		raw[e].dominance = skVal;
		raw[e].total += raw[e].dominance;
		sorted.push({player: e, score: raw[e].total});
	});
	sorted.sort(function(a, b) { 
		return b.score - a.score 
	});
	for(let i = 0; i < sorted.length; i++) {
		mvp[sorted[i].player] = <MvpItem info={mvpDetail(raw[sorted[i].player], sorted[i].player)} place={i+1}/>;
	}
	return mvp;	
}
function mvpDetail(pointsData, name) {
	const display = (val) => Math.floor(100*val);
	return (
		<Box align="center">
		<Heading level="3" color="brand">{name}</Heading>
		<Table>
		<TableBody>
		<TableRow>
		<TableCell>Dominance</TableCell>
		<TableCell>{display(pointsData.dominance)}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Kills</TableCell>
		<TableCell>{display(pointsData.kills)}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Energy</TableCell>
		<TableCell>{display(pointsData.energy)}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Metal</TableCell>
		<TableCell>{display(pointsData.metal)}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Excess Metal Penalty</TableCell>
		<TableCell style={{color:"red"}}>-{display(pointsData.excessM)}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell>Excess Energy Penalty</TableCell>
		<TableCell style={{color:"red"}}>-{display(pointsData.excessE)}</TableCell>
		</TableRow>
		<TableRow>
		<TableCell><Text>Total</Text></TableCell>
		<TableCell><Text>{display(pointsData.total)}</Text></TableCell>
		</TableRow>
		</TableBody>
		</Table>
		</Box>
	);
}
			
function Scores({players, finalScores, seriesData}) {
	const [mvp, setMvp] = useState(false);
	const active = players.filter(e => e.side !== "WATCH");
	const getMaxes = (scores) => {
		let kills = scores.map(val => val.kills);
		let losses = scores.map(val => val.losses);
		let energyProduced = scores.map(val => val.energyProduced);
		let excessEnergy = scores.map(val => val.excessEnergy);
		let metalProduced = scores.map(val => val.metalProduced);
		let excessMetal = scores.map(val => val.excessMetal);
		return {
			kills: Math.max.apply(Math, kills),
			losses: Math.max.apply(Math, losses),
			energyProduced: Math.max.apply(Math, energyProduced),
			excessEnergy: Math.max.apply(Math, excessEnergy),
			metalProduced: Math.max.apply(Math, metalProduced),
			excessMetal: Math.max.apply(Math, excessMetal),
		};
	};
	const renderValue = (val, maxVal) => {
		let percentage = val / maxVal;
		if(percentage === 1) {
			percentage = 0.99;
		}
		return (
			<Box 
			style={{
				height: "32px",
				backgroundImage: "-webkit-gradient(linear, left top, right top, from(#894545), to(#2E3031), color-stop(" + percentage + ", #894545), color-stop(" + percentage + ", #2E3031))"
			}} 
			width="xsmall" 
			textAlign="center"
			justify="center"
			align="center">
			{HRNumbers.toHumanString(Math.floor(val))}
			</Box>
		);
	};
	const maxes = getMaxes(finalScores);
	if(active.length < 3 && !mvp) {
		setMvp(true);
	}
	if(seriesData && !mvp) {
		if(seriesData === "error") {
			setMvp(true);
		} else {
			setMvp(calculateMvp(finalScores, seriesData, maxes));
		}
	}
	
	return (
		<>
		{mvp ? null : <Box>Calculating MVP<BarLoader color="#C8D0D2" loading={true} /></Box>}
		<Box direction="row" pad="small" gap="xsmall" overflow="auto" style={{maxWidth: "fit-content"}}> 
		<Box key="players" gap="xsmall" flex="grow" style={{maxWidth: "250px"}}>
		<Box textAlign="center" align="center">Players</Box>
		{active.map(e => <Box key={e.id} direction="row" align="center" gap="xxsmall"><Image src={"/logos/logo" + (e.color + 1) + ".bmp"}/><Text>{e.name}</Text> {mvp && active.length > 3 ? mvp[e.name] : null}</Box>)}
		</Box>
		<Box key="scores" gap="xsmall" overflow="auto" flex="grow">
		<Box direction="row" gap="xsmall">
		<Box textAlign="center" align="center" width="xsmall">Kills</Box>
		<Box textAlign="center" align="center" width="xsmall">Losses</Box>
		<Box textAlign="center" align="center" width="xsmall">Energy</Box>
		<Box textAlign="center" align="center" width="xsmall">Metal</Box>
		<Box textAlign="center" align="center" width="xsmall">ExcessM</Box>
		<Box textAlign="center" align="center" width="xsmall">ExcessE</Box>
		</Box>
		{active.map(e => {
			const fs = finalScores.find(p => p.player === e.name);
			return (
			<Box direction="row" key={e.name} gap="xsmall"> 
			{renderValue(fs.kills, maxes.kills)}
			{renderValue(fs.losses, maxes.losses)}
			{renderValue(fs.energyProduced, maxes.energyProduced)}
			{renderValue(fs.metalProduced, maxes.metalProduced)}
			{renderValue(fs.excessMetal, maxes.excessMetal)}
			{renderValue(fs.excessEnergy, maxes.excessEnergy)}
			</Box>
			);
		})}
		</Box>
		</Box>
		</>
	);
}
function Chart({gameId, dataCallback}) {
	const [data, setData] = useState({});
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const result = await axios(`${tadaApi.games}/${gameId}?obj=seriesdata`);
			if(result.data === null) {
				dataCallback("error");
				setIsError(true);
				return;
			}
			let seriesKeys = Object.keys(result.data)
			let tmp = [];
			seriesKeys.forEach(e => {
				if(e.slice(0, 9) === "SeriesEPS") {
					tmp.push({
						name: result.data[e].label,
						data: result.data[e].data.map(m => {
							return {
								seconds: m[0],
								evalue: m[1]
							};
						}),
					});
				} else {
					tmp.push({
						name: result.data[e].label,
						data: result.data[e].data.map(m => {
							return {
								seconds: m[0],
								value: m[1]
							};
						}),
					});
				}
			});
			dataCallback(result.data);
			setData(tmp);
			setIsLoading(false);
		};
		fetchData();
	}, [gameId, dataCallback]);
	if(isError) {
		return (
			<Text color="status-error">There was a problem displaying the chart</Text>
		);
	}
	if(isLoading || !Array.isArray(data)) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	return (
		<TadaChart data={data} />
	);
}
const TadaTooltip = ({ active, payload, label }) => {
	if (active && payload) {
		let valid = false;
		const iv = payload[0].value;
		if(payload.length === 1) {
			valid = true;
		} else {
			for(let i = 0; i < payload.length; i++) {
				if(iv !== payload[i].value) {
					valid = true;
				}
			}
			payload.sort((a, b) => b.value - a.value);
		}
		return (
			<Box className="tadaTip" pad="small" background="#1D1F1F" border={{color: "#C8D0D2"}}>
			<Text>{pretty([0, label * 1e9], "s")}</Text>
			{valid ? payload.map(e => {
				return (
					<div key={e.name} className="label">
					<span style={{color: e.stroke}}>{e.name}:</span> {e.value.toFixed(0)}
					</div>
				);
			}) : null}
			</Box>
		);
	}
	return null;
};
class TadaChart extends React.Component {
	constructor(props) {
		super(props);
		let activeInit = {};
		this.props.data.forEach(e => {
			if(e.name.includes("M/s")) {
				activeInit[e.name] = true;
			}});
		const d2 = this.makeSmooth(this.props.data);
		this.state = {
			active: activeInit,
			smoothData: d2,
			smooth: true,
			grid: true,
			tooltip: false
		};
	}
	makeSmooth(data) {
		const x = 32;
		const every_nth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);
		let result = [];
		data.forEach(s => {
			// 32 means smoothing 
			let tmp = [];
			let i;
			if(s.data[0].evalue === undefined) {
				for(i = (x/2); i < s.data.length-(x/2); i++) {
					let ms = s.data.slice(i, i+x);
					let m = 0;
					ms.forEach(e => m += e.value);
					m = m / ms.length;
					tmp.push({
						seconds: s.data[i].seconds,
						value: m
					});
				}
			} else {
				for(i = (x/2); i < s.data.length-(x/2); i++) {
					let ms = s.data.slice(i, i+x);
					let m = 0;
					ms.forEach(e => m += e.evalue);
					m = m / ms.length;
					tmp.push({
						seconds: s.data[i].seconds,
						evalue: m
					});
				}
			}
			// take every 16th value
			result.push({
				name: s.name,
				data: every_nth(tmp, 16)
			});
		});
		return result;
	}
	toggleSeries(series) {
		let active = this.state.active;
		active[series] = !active[series];
		this.setState({active: active});
	}
	render() {
		let toggled = [];
		let store;
		if(this.state.smooth) {
			store = this.state.smoothData;
		} else {
			store = this.props.data;
		}
		store.forEach(e => {
			if(this.state.active[e.name]) {
				toggled.push(e);
			} else {
				const disabledSeries = {
					name: e.name,
					data: [],
					disabled: true
				};
				toggled.push(disabledSeries);
			}
		});
		return (
		<Box>
		<ResponsiveContainer 
		width="100%" height={480}>
		<LineChart
		width={400}
		height={480}
		>
		<YAxis type="number" yAxisId="left" tickCount={10} tick={{fill: "#C8D0D2"}} />
		<YAxis type="number" dataKey="evalue" tickCount={10} yAxisId="right" tick={{fill: "#C8D0D2"}} orientation="right"/>
		<XAxis type="number" dataKey="seconds" tickCount={10} tick={{fill: "#C8D0D2"}} />
		{this.state.tooltip ? <Tooltip content={TadaTooltip} /> : null}
		{this.state.grid ? <CartesianGrid strokeDasharray="3 3" stroke="#454849"/> : null}
		<Legend onClick={e => {this.toggleSeries(e.value)}}/>
		{toggled.map(e => {
			if(e.disabled === true) {
				return (
					<Line
					dot={false}
					legendType="triangle"
					dataKey="nokey-disabled"
					name={e.name}
					key={e.name}
					stroke="#555555"
					/>
				);
			}
			if(e.name.includes("E/s")) {
				return (
					<Line 
					dot={false} 
					dataKey={"evalue"}
					data={e.data} 
					name={e.name} 
					key={e.name} 
					legendType="circle"
					stroke={"#"+intToRGB(hashCode(e.name))}
					yAxisId="right"
					/>
				);
			}
			return (
			<Line 
			dot={false} 
			dataKey={"value"}
			data={e.data} 
			name={e.name} 
			key={e.name} 
			legendType="circle"
			stroke={"#"+intToRGB(hashCode(e.name))}
			yAxisId="left"
			/>
			);
		})}
		</LineChart>
		</ResponsiveContainer>
		<Box alignSelf="center" pad="medium" gap="small" direction="row">
		<CheckBox
		checked={this.state.smooth}
		label="Smooth"
		onChange={(event) => this.setState({smooth: (event.target.checked)})}
		/>
		<CheckBox
		checked={this.state.grid}
		label="Grid"
		onChange={(event) => this.setState({grid: (event.target.checked)})}
		/>
		<CheckBox
		checked={this.state.tooltip}
		label="Tooltip"
		onChange={(event) => this.setState({tooltip: (event.target.checked)})}
		/>
		</Box>
		</Box>
		);
	}
}
function hashCode(str) {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}
function intToRGB(i){
	// Adjust for lighter colors. Take the difference of the total and the maximum value.
	// Then multiply it by alpha, floor the value and add it to the total.
	// Convert the new total.
	let total = i & 0x00FFFFFF;
	let max = 0xFFFFFF; 
	let alpha = 0.75;
	let diff = Math.floor((max - total) * alpha);
	let newTotal = (total + diff).toString(16).toUpperCase();
	return newTotal.padStart(6, "0");
	
}

function frameReducer(state, action) {
	switch(action.type) {
		case "init":
			return action.payload;
		case "update":
			return {
				frames: state.frames,
				current: action.payload
			};
		default:
			throw new Error("unexpected action");
	};
}
function Playback({gameId, players, imgLink720, gifLink720}) {
	const [data, setData] = useState(false);
	const [speed, setSpeed] = useState(100);
	const [frame, setFrame] = useReducer(frameReducer, {frames: 1, current: 1});
	const [gifBlob, setGifBlob] = useState(false);
	const frameCallback = ({frames, current}) => {
		if(!frame || frames > frame.frames) {
			setFrame({type: "init", payload: {frames, current}});
			return;
		}
		setFrame({type: "update", payload: current});
	}
	useEffect(() => {
		const fetchData = async () => {
			const linkResult = await axios(`${tadaApi.download}/?id=${imgLink720}`);
			fetch(linkResult.data.signedUrl).then(r => r.blob()).then(img => {
				setData(URL.createObjectURL(img));
			});
		};
		fetchData();
	}, [imgLink720]);
	useEffect(() => {
		const fetchData = async () => {
			const linkResult = await axios(`${tadaApi.download}/?id=${gifLink720}`);
			fetch(linkResult.data.signedUrl).then(r => r.blob()).then(img => {
				setGifBlob(URL.createObjectURL(img));
			});
		};
		fetchData();
	}, [gifLink720]);
	if(!data || !gifBlob) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	return (
		<Box pad="small" wrap direction="row" overflow="auto">
		<Stack anchor="top-left">
		<Image src={data} />
		<GifPlayer 
		src={gifBlob} 
		frameCallback={(n, c) => frameCallback({frames: n, current: c})}
		speedCallback={() => speed/100}
		/>
		</Stack>
		<Box direction="column" wrap gap="xsmall" margin="small">
		{players.map(e => (
			<Box key={e.name} direction="row" gap="xsmall" align="center">
			<Image src={"/logos/logo"+(e.color+1)+".bmp"} alt={e.name}/>
			{e.name}
			</Box>
		))}
		Speed
		<RangeInput
		value={speed}
		onChange={e => setSpeed(e.target.value)}
		/>
		<p>{pretty([0, frame.current*1e10], "s")}<br />{((frame.current / frame.frames)*100).toFixed(0) + "%"}</p>
		</Box>
		</Box>
	);
}
// Sorting functions for Units
const firstProducedSortFunc = (a, b) =>  {
	return a.FirstProduced - b.FirstProduced
};
const totalProducedSortFunc = (a, b) =>  {
	return b.Produced - a.Produced
};

const kdSortFunc = (a, b) =>  {
	let kda = getKD(a.Kills, a.Deaths, a.DamageDealt);
	let kdb = getKD(b.Kills, b.Deaths, b.DamageDealt);
	return kdb.kd - kda.kd
};

const damageDealtSortFunc = (a, b) =>  {
	return b.DamageDealt - a.DamageDealt
};
function Units({gameId, vers, players}) {
	const [unitRecords, setUnitRecords] = useState({});
	const [unitMapping, setUnitMapping] = useState(null);
	const [sortMode, setSortMode] = useState({
		mode: "First Produced",
		sortFunc: firstProducedSortFunc
	});
	const c4 = loadedMods[vers.slice(0, 4)].checksum;
	const manifest = loadedMods[c4];

	// Sorting functions requiring context
	const metalSpentSortFunc = (a, b) =>  {
		return b.Produced * unitMapping[b.id-1].buildcostmetal - a.Produced * unitMapping[a.id-1].buildcostmetal
	};

	// Menu for sorting options
	let orderingItems = [
		{
			label: "First Produced", 
			onClick: () => setSortMode({
				mode: "First Produced", 
				sortFunc: firstProducedSortFunc
			}),
		},
		{
			label: "Total Produced",
			onClick: () => setSortMode({
				mode: "Total Produced",
				sortFunc: totalProducedSortFunc
			}),
		},
		{
			label: "K/D",
			onClick: () => setSortMode({
				mode: "K/D",
				sortFunc: kdSortFunc
			})
		},
		{
			label: "Damage Dealt",
			onClick: () => setSortMode({
				mode: "Damage Dealt",
				sortFunc: damageDealtSortFunc
			}),
		}
	];
	if(manifest.features && manifest.features.includes("buildcostmetal")) {
		orderingItems = orderingItems.concat(
			{
				label: "Metal Spent",
				onClick: () => setSortMode({
					mode: "Metal Spent",
					sortFunc: metalSpentSortFunc
				})
			}
		);
	}
	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(`${tadaApi.games}/${gameId}?obj=unitrecords`);
			setUnitRecords(result.data);
		};
		fetchData();
	}, [gameId]);
	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(`/mods/${c4}/units.json`);
			setUnitMapping(result.data);
		};
		fetchData();
	}, [c4]);
	let pmap = {};
	let i, j;
	for(i in players) {
		pmap[players[i].number] = players[i].name;
	}
	if(!Array.isArray(unitRecords) || !unitMapping) {
		return (
			<BarLoader color="#C8D0D2" loading={true}/>
		);
	}
	let resultRecords = [];
	for(i in unitRecords) {
		if(unitRecords[i]) {
			let urs = [];
			let elements = [];
			for(j in unitRecords[i]) {
				let unit = unitRecords[i][j];
				unit.id = j;
				elements.push(unit);
			}
			const sortedRecords = elements.sort(sortMode.sortFunc);
			for(j in sortedRecords) {
				const fp = pretty([
					0,
					Math.ceil(sortedRecords[j].FirstProduced) * 1000000
				], "s");
				const kdi = getKD(sortedRecords[j].Kills, sortedRecords[j].Deaths, sortedRecords[j].DamageDealt);
				const info = (
					<Table>
					<TableBody>
					{manifest.features.includes("name") ? (
						<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>{unitMapping[sortedRecords[j].id-1].name}</TableCell>
						</TableRow>
					) : null}
					<TableRow>
					<TableCell>Total Produced</TableCell>
					<TableCell>{sortedRecords[j].Produced}</TableCell>
					</TableRow>
					<TableRow>
					<TableCell>First Produced</TableCell>
					<TableCell>{fp}</TableCell>
					</TableRow>
					<TableRow>
					<TableCell>K/D</TableCell>
					<TableCell>{kdi.s}</TableCell>
					</TableRow>
					<TableRow>
					<TableCell>Damage</TableCell>
					<TableCell>
					{HRNumbers.toHumanString(sortedRecords[j].DamageDealt) + "/" + HRNumbers.toHumanString(sortedRecords[j].DamageReceived)}</TableCell>
					</TableRow>
					{manifest.features.includes("buildcostmetal") ? (
						<TableRow>
						<TableCell>Metal</TableCell>
						<TableCell>
						{HRNumbers.toHumanString(unitMapping[sortedRecords[j].id-1].buildcostmetal * sortedRecords[j].Produced)}
						</TableCell>
						</TableRow>
					) : null}
					</TableBody>
					</Table>
				);
				urs.push(
					<Stack anchor="top-right" key={pmap[i]+j}>
					<UnitItem
					mod={c4}
					unit={unitMapping[parseInt(sortedRecords[j].id, 10)-1].unitname}
					info={info}
					/>
					<Box
					background="dark-2"
					pad="xxsmall"
					>
					{sortedRecords[j].Produced}
					</Box>
					</Stack>
				);
			}
			const playerKey = pmap[parseInt(i, 10)+1];
			resultRecords.push(
				<Box 
				direction="column" 
				gap="small" 
				pad="xsmall" 
				key={playerKey+"us"}
				>
				<Box key="ph">
				<Heading 
				level="4" 
				key={playerKey+"h"}
				>
				{playerKey}
				</Heading>
				</Box>
				<Box key="urs" direction="row" wrap>{urs}</Box>
				</Box>
			);
		}
	}
	return (
		<Box>
		<Stack anchor="top-right">
		<Box direction="row" gap="small" pad="xsmall" wrap>
		{resultRecords}
		</Box>
		<Heading level="4" margin="medium">
		<Menu label="Order by" items={orderingItems} />
		{sortMode.mode}
		</Heading>
		</Stack>
		</Box>
	);
}
class UnitItem extends React.Component {
	state = {};
	ref = React.createRef();
	render() {
		const { over } = this.state;
		return (
			<Box ref={this.ref} margin="xsmall">
			<Image
			style={{width: "72px", height: "72px"}}
			onMouseEnter={() => this.setState({over: true})}
			onMouseOut={() => this.setState({over: false})}
			src={"/mods/"+this.props.mod+"/unitpic/"+this.props.unit.toLowerCase()+".pcx.png"}
			/>
			{this.ref.current && over && (
				<Drop 
				align={{bottom: "top"}}
				target={this.ref.current}
				plain
				>
				<Box
				pad="small"
				border={{color: "#C8D0D2"}}
				background="#1D1F1F"
				>
				{this.props.info}
				</Box>
				</Drop>
			)}
			</Box>
		);
	}
}

function getKD(kills, deaths, damageDealt) {
	let k = 0;
	let d = 0;
	let i;
	if(deaths) {
		for(i in deaths) {
			d = d + deaths[i];
		}
	}
	if(kills) {
		for(i in kills) {
			k = k + kills[i];
		}
	}
	if(damageDealt < 20) {
		k = 0;
	}
	if(k === 0 && d > 0) {
		return {
			kd: Number.NEGATIVE_INFINITY,
			s: k + "/" + d
		}
	}
	if(d === 0 && k > 0) {
		return {
			kd: Number.POSITIVE_INFINITY,
			s: k + "/" + d
		}
	}
	let kd = k/d;
		if(isNaN(kd)) {
		kd = 0;
	}
	return {
		kd: kd,
		s: k + "/" + d
	}
}
	
export default Demo;
